import { Link, useLocation } from "react-router-dom";
import React, { useState } from "react";
export default function Navbar() {
  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];
  const [activeLink, setActiveLink] = useState(
    currentPath !== "" ? currentPath : "home"
  );
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = (link) => {
    setIsMenuOpen(true);
    setActiveLink(link);
  };
  return (
    <>
      <header className="focus:ring-4 focus:outline-none p-2 mx-3 my-2 rounded-lg shadow-[5px_5px_15px_#bebebe,-5px_-5px_10px_#ffffff]">
        <nav className="border-gray-200 ">
          <div className="flex flex-wrap items-center justify-between mx-auto p-4">
            <Link
              to="/"
              className="flex items-center space-x-3 rtl:space-x-reverse"
            >
              <img src="/logo192.png" className="h-15" alt="edgelearn.site" />
              {/* <h1 className="font-extrabold text-transparent text-center text-5xl bg-clip-text bg-gradient-to-r from-amber-400 via-orange-700 to-orange-400">
                Web 03
              </h1> */}
            </Link>
            <div className="flex">
              <button
                data-collapse-toggle="navbar-search"
                type="button"
                className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-white rounded-lg md:hidden hover:bg-orange-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                aria-controls="navbar-search"
                aria-expanded="false"
                onClick={handleMenuToggle}
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 17 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 1h15M1 7h15M1 13h15"
                  />
                </svg>
              </button>
            </div>
            <div
              className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${
                isMenuOpen ? "hidden" : ""
              }`}
              id="navbar-search"
            >
              <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium rounded-lg md:space-x-8 md:flex-row md:mt-0 md:border-0 bg-slate-900 md:bg-transparent border-0">
                <li>
                  <Link
                    to="/"
                    className={`block py-2 px-3 rounded  md:p-0 text-transparent bg-gradient-to-r bg-clip-text ${
                      activeLink === "home"
                        ? "font-bold from-purple-400 via-amber-300 to-blue-500"
                        : "from-teal-100 to-blue-200 "
                    }`}
                    onClick={() => handleLinkClick("home")}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    className={`block py-2 px-3 rounded md:p-0 text-transparent bg-gradient-to-r bg-clip-text ${
                      activeLink === "about"
                        ? "font-bold from-purple-400 via-amber-300 to-blue-500"
                        : "from-teal-100 to-blue-200 "
                    }`}
                    onClick={() => handleLinkClick("about")}
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to="/privacy-policy"
                    className={`block py-2 px-3 rounded md:p-0 text-transparent bg-gradient-to-r bg-clip-text ${
                      activeLink === "privacy-policy"
                        ? "font-bold from-purple-400 via-amber-300 to-blue-500"
                        : "from-teal-100 to-blue-200 "
                    }`}
                    onClick={() => handleLinkClick("privacy-policy")}
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/term-condition"
                    className={`block py-2 px-3 rounded md:p-0 text-transparent bg-gradient-to-r bg-clip-text ${
                      activeLink === "term-condition"
                        ? "font-bold from-purple-400 via-amber-300 to-blue-500"
                        : "from-teal-100 to-blue-200 "
                    }`}
                    onClick={() => handleLinkClick("term-condition")}
                  >
                    Term Condition
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact-us"
                    className={`block py-2 px-3 rounded md:p-0 text-transparent bg-gradient-to-r bg-clip-text ${
                      activeLink === "contact-us"
                        ? "font-bold from-purple-400 via-amber-300 to-blue-500"
                        : "from-teal-100 to-blue-200"
                    }`}
                    onClick={() => handleLinkClick("contact-us")}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}
